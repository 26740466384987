<template>
  <div class="frame">
    <div class="user flex j_b a_c f_c">
      <img src="../assets/logo.png" alt="" class="avatar">
      <div class="name">英才小管家</div>
    </div>
    <div class="bar" v-for="(list, num) in lists" :key="num">
      <div v-for="(item, index) in list" :key="index">
        <div class="line flex j_b a_c" :class="item.value == active && 'active'"
          v-if="!item.auth || (user.u_type || 0 % 10) >= item.auth"
          @click="item.tap(item)">
          <img :src="item.icon" class="icon">
          <div class="label">{{ item.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'frameComp',
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      lists: [
        [
          { label: '个人管理', icon: require('../assets/img/学位.svg'), value: 1, tap: this.onJump, url: '/home' },
          { label: '我的借用', icon: require('../assets/img/房子.svg'), value: 2, tap: this.onJump, url: '/room' },
          { label: '我的活动', icon: require('../assets/img/路标.svg'), value: 3, tap: this.onJump, url: '/active' },
          { label: '我的文章', icon: require('../assets/img/学问.svg'), value: 4, tap: this.onJump, url: '/essay' },
          { label: '我的组织', icon: require('../assets/img/人脉.svg'), value: 5, tap: this.onJump, url: '/organization' },
          { label: '聊天助手', icon: require('../assets/img/信件往来.svg'), value: 13, tap: this.onJump, url: '/chatai' },
        ], [
          { label: '用户管理', icon: require('../assets/img/会议.svg'), value: 10, tap: this.onJump, url: '/admin/user', auth: 2 },
          { label: '房间管理', icon: require('../assets/img/房子.svg'), value: 6, tap: this.onJump, url: '/admin/room', auth: 2 },
          { label: '活动管理', icon: require('../assets/img/路标.svg'), value: 8, tap: this.onJump, url: '/admin/active', auth: 1 },
          { label: '文章管理', icon: require('../assets/img/学问.svg'), value: 7, tap: this.onJump, url: '/admin/essay', auth: 1 },
          { label: '实验管理', icon: require('../assets/img/其他手段.svg'), value: 15, tap: this.onJump, url: '/admin/device', auth: 1 },
          { label: '组织管理', icon: require('../assets/img/人脉.svg'), value: 9, tap: this.onJump, url: '/admin/organization', auth: 1 },
          { label: '通知管理', icon: require('../assets/img/演示.svg'), value: 12, tap: this.onJump, url: '/admin/notice', auth: 2 },
          { label: '登录管理', icon: require('../assets/img/手机.svg'), value: 14, tap: this.onJump, url: '/admin/login', auth: 2 },
        ], [
          { label: '退出登录', icon: require('../assets/img/设置.svg'), value: 11, tap: this.onLogout, url: '' },
        ]
      ],
      user: { u_type: 0 }
    }
  },

  created() {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    this.user = user;
  },

  methods: {
    onJump(e) {
      this.$router.replace(e.url);
    },
    onLogout() {
      const myDialog = this.$dialog.confirm({
        header: '提示',
        body: '确认退出登录？',
        confirmBtn: '确认',
        cancelBtn: '下次一定',
        showInAttachedElement: true,
        onConfirm: () => {
          localStorage.removeItem('user');
          this.$router.replace('/login');
          myDialog.destroy();
        },
      });
    },
  }
}
</script>

<style lang="scss">
.frame{
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  font-size: 16px;
  width: 16em;
  height: 100vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .user{
    background-color: white;
    margin: 12px;
    padding: 12px 0;
    font-size: 16px;
    width: 8em;
    box-shadow: 0 0 20px 5px #00000020;
    border-radius: 12px;
    .avatar{
      width: 4em;
      height: 4em;
      border-radius: 50%;
      box-shadow: 0 0 10px 3px #00000020;
    }
    .name{
      font-size: 12px;
      color: #666666;
      margin-top: 1em;
    }
  }
  .bar{
    margin-top: 40px;
    transition: 300ms;
    .line{
      margin: 12px 6px 12px 12px;
      background-color: white;
      box-shadow: 0 0 20px 5px #00000020;
      border-radius: 12px;
      padding: 6px 12px;
      transition: 300ms;
      font-size: 16px;
      width: max-content;
      .icon{
        width: 1.5em;
        height: 1.5em;
        transition: 300ms;
      }
      .label{
        text-align: right;
        font-size: 14px;
        width: 5em;
      }

      &:hover{
  cursor: pointer;
        transform: translateX(10%);
        background-color: #25A3FF;
        color: white;
      }
      &:active{
        transform: translateX(5%);
      }
      &.active{
        transform: translateX(5%) scale(1.1);
        font-weight: 600;
        .icon{
          width: 1.6em;
          height: 1.6em;
        }
      }
    }
  }
  &::after{
    content: '';
    z-index: -1;
    position: fixed;
    // inset: 0 60% 0 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    font-size: 16px;
    width: 6em;
    background-color: #ffffffff;
    // filter: blur(1px);
    border-radius: 0 12px 12px 0;
    box-shadow: 0 0 20px 5px #00000020;
  }
}
</style>